import { pastZakaatDetails, deleteZakaatDetails, downloadZakaatSummary } from '@/api/zakat'
const FileDownload = require('js-file-download')

const state = {
  pastZakaat: null,
  zakaatStatus: null
}

const mutations = {
  setPastZakaat (state, zakaat) {
    state.pastZakaat = zakaat
  },
  setZakaatStatus (state, status) {
    state.zakaatStatus = status
  }
}

const actions = {
  async setZakaat ({ commit, dispatch }, zakaat) {
    await dispatch('setZakaatStatus', 'loading')
    await commit('setPastZakaat', zakaat)
    await dispatch('setZakaatStatus', 'ready')
  },
  async fetchPastZakaat ({ dispatch }) {
    await dispatch('setZakaatStatus', 'loading')
    const zakaat = await pastZakaatDetails()
    await dispatch('setZakaatStatus', 'ready')
    await dispatch('setZakaat', zakaat.data)
  },
  async setZakaatStatus ({ commit }, status) {
    await commit('setZakaatStatus', status)
  },
  async deleteZakaatDetails ({ dispatch }, id) {
    await deleteZakaatDetails(id)
    await dispatch('fetchPastZakaat')
  },
  async downloadZakaatSummary ({ rootState, dispatch }, id) {
    await downloadZakaatSummary(id).then((summary) => {
      const downloadData = {
        data: summary.data,
        fileName: `Alim-Zakaat-${id}.pdf`
      }
      if (rootState.mode.isApp) {
        dispatch('downloadFile', downloadData, { root: true })
      } else {
        FileDownload(downloadData.data, downloadData.fileName)
      }
    })
  }
}

const getters = {
  pastZakaat: state => {
    if (state.pastZakaat && state.pastZakaat.length > 0) {
      const items = [...state.pastZakaat].sort((a, b) => {
        return b.spotPriceDate - a.spotPriceDate
      })
      return items
    } else {
      return state.pastZakaat
    }
  },
  zakaatStatus: state => state.zakaatStatus
}

export default {
  state,
  mutations,
  actions,
  getters
}
