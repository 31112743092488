import axios from 'axios'
import store from '../store'
const creditCard = (transactionReference, redirectUrl, captchaToken) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/creditCard`, {
    // 'estimatedValue': 0,
    transactionReference,
    redirectUrl,
    captchaToken
  }, {
    headers: { baseSystemType: 'PUBLIC_WEBSITE' }
  })
}
const creditCardPayFast = (transactionRef, returnUrl, captchaToken) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/initiate/payFast/creditCard`, {
    // 'estimatedValue': 0,
    transactionReference: transactionRef,
    returnUrl,
    cancelUrl: returnUrl,
    notifyUrl: `${process.env.VUE_APP_URL_BASE}/adms/api/donations/call-back/pay-fast`,
    captchaToken,
    additionalPaymentOptionsEnabled: true
  }, {
    headers: { baseSystemType: 'PUBLIC_WEBSITE' }
  })
}
const getPayFastTransactionStatus = (transactionReference) => {
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/creditCard/payFast/verify/${transactionReference}`, {
    headers: { baseSystemType: 'PUBLIC_WEBSITE' }
  })
}

const getReceiptUrl = (transactionId, token) => {
  let headers = {}
  if (store.state.user.jwt !== null) {
    headers = {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  } else {
    headers = {
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  }
  return axios.get(`${process.env.VUE_APP_URL_BASE}/adms/api/transactions/v2/receipts/download/${transactionId}`, {
    headers,
    responseType: 'blob'
  })
}

const getDonationReference = (id, email, donationList, checkoutDetails) => {
  let giftAid = null
  if (store.state.cart.giftAid) {
    giftAid = store.state.cart.giftAid
  } else if (checkoutDetails) {
    giftAid = checkoutDetails.giftAid
  }
  const req = {
    id: id || 0,
    email,
    donationDtoList: donationList,
    giftAid,
    sponsored: checkoutDetails.sponsored,
    ownMoney: checkoutDetails.ownMoney,
    paymentOption: checkoutDetails.paymentOption,
    anonymousDonorDTO: checkoutDetails.anonymousDetails,
    bankAccountID: checkoutDetails.bankAccountID
  }
  if (email) {
    req.userID = store.state.user.user.userID
    return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/v2/withDonorProfile`, req, {
      headers: {
        Authorization: `Bearer ${store.state.user.jwt}`,
        baseSystemType: 'PUBLIC_WEBSITE'
      }
    })
  } else {
    return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/v2/anonymous`, req, {
      headers: {
        baseSystemType: 'PUBLIC_WEBSITE'
      }
    })
  }
}
const getGroupDonationReference = (donationList, checkoutDetails) => {
  const req = {
    transactionDTO: {
      donationDtoList: donationList,
      userID: checkoutDetails.userID,
      paymentOption: checkoutDetails.paymentOption
    },
    groupID: checkoutDetails.groupID,
    madeByFounder: true
  }
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donor-group/donation`, req, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getBankDonationReference = (donation) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/v2/withDonorProfile`, donation, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const getBankDonationRecurringReference = (donation) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/debitOrders/save`, donation, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}
const debitOrdersZA = (donation) => {
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/debitOrders/za/save`, donation, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

export {
  getDonationReference,
  getGroupDonationReference,
  getBankDonationReference,
  getBankDonationRecurringReference,
  creditCard,
  creditCardPayFast,
  getPayFastTransactionStatus,
  getReceiptUrl,
  debitOrdersZA
}
