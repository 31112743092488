import { downloadRecurringDonationSummary, downloadRecurringDonationSummaryBreakdown } from '@/api/recurringDonations/download'
const FileDownload = require('js-file-download')

const actions = {
  async downloadRecurringDonationSummary ({ rootState, dispatch }, id) {
    try {
      downloadRecurringDonationSummary(id).then((result) => {
        const downloadData = {
          data: result.data,
          fileName: `${rootState.user.user.donorID}_${id}.pdf`
        }
        if (rootState.mode.isApp) {
          dispatch('downloadFile', downloadData, { root: true })
        } else {
          FileDownload(downloadData.data, downloadData.fileName)
        }
      })
    } catch (error) {
      console.error('ERRR', error)
      return 'Error'
    }
  },
  async downloadRecurringDonationSummaryBreakdown ({ rootState, dispatch }, id) {
    try {
      downloadRecurringDonationSummaryBreakdown(id).then((result) => {
        const downloadData = {
          data: result.data,
          fileName: `${rootState.user.user.donorID}_${id}.pdf`
        }
        if (rootState.mode.isApp) {
          dispatch('downloadFile', downloadData, { root: true })
        } else {
          FileDownload(downloadData.data, downloadData.fileName)
        }
      })
    } catch (error) {
      console.error('ERRR', error)
      return 'Error'
    }
  }
}

export default {
  actions
}
