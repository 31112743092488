import { createRouter, createWebHistory } from 'vue-router'

const country = process.env.VUE_APP_COUNTRY
let donationCheckout = {
  path: '/checkout/:id',
  name: 'Donation Checkout',
  component: () => import(/* webpackChunkName: "group-checkout" */ '../pages/checkout/Checkout.vue'),
  meta: {
    description: 'View your donation cart'
  }
}
if (country === 'UK') {
  donationCheckout = {
    path: '/checkout/:id',
    name: 'Donation Checkout',
    component: () => import(/* webpackChunkName: "group-checkout" */ '../pages/checkout/CheckoutUk.vue'),
    meta: {
      description: 'View your donation cart'
    }
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../pages/home/Home.vue'),
    meta: {
      description: 'South African based non-profit relief organisation dedicated to providing humanitarian services in crisis and non-crisis situations'
    }
  },
  {
    path: '/deleteaccount',
    name: 'Request Account Delete Page',
    component: () => import(/* webpackChunkName: "home" */ '../pages/profile/deleteAccount/DeleteAccount.vue'),
    meta: {
      description: 'This is Home description'
    }
  },
  {
    path: '/reset',
    name: 'Reset password',
    component: () => import(/* webpackChunkName: "group-auth" */ '../pages/resetpassword/ResetPassword.vue'),
    props: route => ({ query: route.query }),
    meta: {
      description: 'Reset you password'
    }
  },
  {
    path: '/profile/:id',
    name: 'Donor Profile',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profileedit',
    name: 'Edit Profile',
    component: () => import(/* webpackChunkName: "group-profile" */ '../pages/profile/pages/ProfileEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/donorgroups',
    name: 'Donor Groups',
    component: () => import(/* webpackChunkName: "group-groups" */ '../pages/profile/pages/manage/donorgroups/DonorGroups.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/donorgroupconfirmation',
    name: 'Donor Group Confirmation',
    component: () => import(/* webpackChunkName: "group-groups" */ '../pages/profile/pages/manage/donorgroups/DonorGroupConfirmation.vue'),
    props: route => ({ query: route.query.donorGroupInvite }),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/grouptransactions/:id',
    name: 'Group Transactions',
    component: () => import(/* webpackChunkName: "group-groups" */ '../pages/profile/pages/manage/donorgroups/GroupTransactions.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/managezakaah',
    name: 'Zakaah Calculations',
    component: () => import(/* webpackChunkName: "group-zakaah" */ '../pages/profile/pages/manage/zakaah/ManageZakaah.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/organisations',
    name: 'Organisations',
    component: () => import(/* webpackChunkName: "group-organisations" */ '../pages/profile/pages/manage/organisations/Organisations.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/organisationnew',
    name: 'New Organisation',
    component: () => import(/* webpackChunkName: "group-organisations" */ '../pages/profile/pages/manage/organisations/OrganisationNew.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/organisationedit/:id',
    name: 'Edit Organisation',
    component: () => import(/* webpackChunkName: "group-organisations" */ '../pages/profile/pages/manage/organisations/OrganisationEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/organisationdonations/:id',
    name: 'Organisation Donations',
    component: () => import(/* webpackChunkName: "group-organisations" */ '../pages/profile/pages/manage/organisations/OrganisationDonations.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/managesadaqah',
    name: 'Manage Daily Sadaqah',
    component: () => import(/* webpackChunkName: "group-sadaqah" */ '../pages/profile/pages/manage/sadaqah/ManageSadaqah.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manage18a',
    name: 'Manage Section 18A',
    component: () => import(/* webpackChunkName: "group-manage18" */ '../pages/profile/pages/manage/18a/Manage18A.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/giftaidpreferences',
    name: 'GiftAid Preferences',
    component: () => import(/* webpackChunkName: "group-giftaid" */ '../pages/profile/pages/manage/giftaid/GiftAidPreferences.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dailysadaqah',
    name: 'Daily Sadaqah',
    component: () => import(/* webpackChunkName: "sadaqah" */ '../pages/dailysadaqah/DailySadaqah.vue'),
    meta: {
      description: 'Change a life, every day.'
    }
  },
  donationCheckout,
  {
    path: '/donate',
    name: 'Donate Now',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/donate/Donate.vue'),
    meta: {
      description: 'Make a difference with your donations today.'
    }
  },
  {
    path: '/paymentcomplete',
    name: 'Payment Complete',
    component: () => import(/* webpackChunkName: "group-checkout" */ '../pages/paymentcomplete/za/PaymentComplete.vue'),
    meta: {
      description: 'Thank you for donating.'
    }
  },
  {
    path: '/recurringpaymentcomplete/:subscriptionRef/:donationId',
    name: 'Recurring Payment Complete',
    component: () => import(/* webpackChunkName: "group-checkout" */ '../pages/paymentcomplete/za/RecurringCreditCard.vue'),
    meta: {
      description: 'Thank you for setting up your recurring donation.'
    }
  },
  {
    path: '/paymentcompleteUk',
    name: 'Payment Complete ',
    component: () => import(/* webpackChunkName: "group-checkout" */ '../pages/paymentcomplete/uk/PaymentCompleteUK.vue'),
    props: route => ({ query: route.query }),
    meta: {
      description: 'Thank you for donating.'
    }
  },
  {
    path: '/recurringpaymentcompleteUk/:subscriptionRef/:donationId',
    name: 'Recurring Payment Complete ',
    component: () => import(/* webpackChunkName: "group-checkout" */ '../pages/paymentcomplete/uk/PaymentCompleteRecurringUK.vue'),
    meta: {
      description: 'Thank you for setting up your recurring donation.'
    }
  },
  {
    path: '/recurringdonations',
    name: 'Recurring Donations ',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/RecurringDonations.vue'),
    meta: {
      requiresAuth: true,
      description: 'View and manage your recurring donations'
    }
  },
  {
    path: '/managerecurringdonations',
    name: 'Recurring Donations',
    component: () => import(/* webpackChunkName: "group-recurring" */ '../pages/profile/pages/manage/recurringdonations/ManageRecurringDonations.vue'),
    meta: {
      requiresAuth: true,
      description: 'View and manage your recurring donations'
    }
  },
  {
    path: '/debitorders',
    name: 'Debit Orders',
    component: () => import(/* webpackChunkName: "group-recurring" */ '../pages/DebitOrders.vue'),
    meta: {
      requiresAuth: true,
      description: 'View and manage your debit orders '
    }
  },
  {
    path: '/pledges',
    name: 'Pledged Donations',
    component: () => import(/* webpackChunkName: "group-recurring" */ '../pages/Pledges.vue'),
    meta: {
      requiresAuth: true,
      description: 'View, manage and complete your pledges'
    }
  },
  {
    path: '/appeals/:id',
    name: 'Appeals',
    component: () => import(/* webpackChunkName: "group-appeals" */ '../pages/appeals/Appeals.vue'),
    meta: {
      description: 'Browse through all the ways you can make a difference.'
    }
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "group-news" */ '../pages/news/News.vue'),
    meta: {
      description: 'View the latest news'
    }
  },
  {
    path: '/news/:id',
    name: 'News Article',
    component: () => import(/* webpackChunkName: "group-news" */ '../pages/news/NewsIndividual.vue'),
    meta: {
      description: 'Individual news article'
    }
  },
  {
    path: '/ourwork',
    name: 'Our Work',
    component: () => import(/* webpackChunkName: "group-ourwork" */ '../pages/ourwork/OurWork.vue'),
    meta: {
      description: 'Empowering lives in over 75 countries worldwide'
    }
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: () => import(/* webpackChunkName: "group-project" */ '../pages/project/ProjectIndividual.vue'),
    meta: {
      description: 'Individual project'
    }
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import(/* webpackChunkName: "group-contact" */ '../pages/resources/contact/Contact.vue'),
    meta: {
      description: 'Get in touch'
    }
  },
  {
    path: '/aboutus',
    name: 'About Us',
    component: () => import(/* webpackChunkName: "group-about" */ '../pages/about/AboutUs.vue'),
    meta: {
      description: 'Striving to serve humanity for over twenty years'
    }
  },
  {
    path: '/section18a',
    name: 'Section 18A',
    component: () => import(/* webpackChunkName: "group-resources-18a" */ '../pages/resources/section18a/Section18A.vue'),
    meta: {
      description: 'Tax Returns Benefits when donating to the Al-Imdaad Foundation'
    }
  },
  {
    path: '/giftaid',
    name: 'The Gift Aid Scheme',
    component: () => import(/* webpackChunkName: "group-resources-giftaid" */ '../pages/resources/giftaid/GiftAid.vue'),
    meta: {
      description: 'Gift Aid is a government scheme which is open to all charity supporters who pay UK Income and Capital Gains tax'
    }
  },
  {
    path: '/volunteer',
    name: 'Volunteer',
    component: () => import(/* webpackChunkName: "group-volunteer" */ '../pages/resources/volunteer/Volunteer.vue'),
    meta: {
      description: 'Become an ambassador of change today'
    }
  },
  {
    path: '/waystodonate',
    name: 'Donation Methods',
    component: () => import(/* webpackChunkName: "group-donate" */ '../pages/resources/waystodonate/WaysToDonate.vue'),
    meta: {
      description: 'Ways to Donate'
    }
  },
  {
    path: '/zakaahcalculator/:id',
    name: 'Zakaah Calculator',
    component: () => import(/* webpackChunkName: "group-zakaah" */'../pages/resources/zakaahcalculator/ZakaahCalculator.vue'),
    meta: {
      description: 'This calculator will assist you to calculate your zakaat.'
    }
  },
  {
    path: '/verifiedfundraisers',
    name: 'Verified Fundraisers',
    component: () => import(/* webpackChunkName: "group-fundraisers" */'../pages/resources/verifiedfundraisers/VerifiedFundraisers.vue'),
    meta: {
      description: 'Search for a verified fundraiser near you'
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "group-search" */ '../pages/search/SearchPage.vue'),
    meta: {
      description: 'Enter a keyword to search for appeals or project updates.'
    }
  },
  {
    path: '/appdownload',
    name: 'App Download',
    component: () => import(/* webpackChunkName: "group-app" */ '../pages/resources/appdownload/AppDownload.vue'),
    meta: {
      description: 'Serve humanity on-the-go with our official app'
    }
  },
  {
    path: '/donationspolicy',
    name: 'Donations Policy',
    component: () => import(/* webpackChunkName: "group-legal" */ '../pages/legal/DonationsPolicy.vue'),
    meta: {
      description: 'It is important to mention that Al-Imdaad Foundation operate a 100% donation policy for Zakaat and Sadaqaah'
    }
  },
  {
    path: '/privacypolicy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "group-legal" */ '../pages/legal/PrivacyPolicy.vue'),
    meta: {
      description: 'Al-Imdaad Foundation respects the privacy of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application or visit our Website'
    }
  },
  {
    path: '/terms',
    name: 'Terms & Conditions',
    component: () => import(/* webpackChunkName: "group-legal" */ '../pages/legal/Terms.vue'),
    meta: {
      description: 'The Al-Imdaad Foundation is a non-profit humanitarian aid relief organisation (NGO) registered in the Republic of South Africa, dedicated to providing humanitarian services in crisis and non-crisis situations to most needy orphans, widows and destitute, irrespective of race, religion, culture, creed and geographical boundary'
    }
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: () => import(/* webpackChunkName: "group-legal" */ '../pages/legal/Disclaimer.vue'),
    meta: {
      description: 'The information contained in this website is for general information purposes only.'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeResolve(async to => {
  window.scrollTo(0, 0)
})
router.beforeEach((to, from, next) => {
  console.log('to', to)

  if (to.hash && to.path === '/') {
    try {
      const hashStr = to.hash.substring(1) // Remove the leading '#'
      let path = hashStr
      const query = {}

      if (hashStr.includes('?')) {
        const parts = hashStr.split('?')
        path = parts[0]
        const queryString = parts[1]

        const params = queryString.split('&')
        params.forEach(param => {
          const keyValue = param.split('=')
          if (keyValue.length === 2) {
            query[decodeURIComponent(keyValue[0])] = decodeURIComponent(keyValue[1])
          }
        })
      }

      next({
        path: path,
        query: query,
        replace: true // replace the history entry so that the user does not go back to the hash url.
      })
    } catch (error) {
      console.error('Error parsing hash:', error)
      next({ path: '/', replace: true })// if there is an error redirect to the root.
    }
  } else {
    next()
  }
})
export default router
