import { getCardCharges } from '@/api/cardCharges/get'

const actions = {
  async getCardCharges ({ getters }, details) {
    console.log('getCardCharges, sponsored', details.sponsored)
    console.log('getCardCharges getters.user', getters.user)
    const donationDtoList = []
    const email = (getters.user ? getters.user.email : '')
    let currencyID = 1
    if (details.cart && details.cart.length > 0 && details.cart[0] && details.cart[0].currency && details.cart[0].currency !== 'R') {
      currencyID = 3
    } else if (details.defaultCurrency && details.defaultCurrency !== 'R') {
      currencyID = 3
    }
    if (details.cart && details.cart.length > 0) {
      details.cart.forEach(item => {
        let obj = {}
        if (item.donationTypes && item.donationTypes.length > 0) {
          item.donationTypes.forEach(type => {
            if (type.amount && type.amount > 0) {
              const donationTypeID = type.type ? type.type.donationType.donationTypeID : null
              let hasPlaque = false
              if (item.plaqueDescription) {
                hasPlaque = true
              }
              obj = {
                email,
                donorID: (getters.user ? getters.user.donorID : ''),
                amount: type.amount,
                donationTypeID,
                projectID: item.projectId ? item.projectId : null,
                notes: item.comment || '',
                personalizedPlaque: hasPlaque,
                plaqueDescription: item.plaqueDescription,
                paymentGatewayResponse: '',
                currencyId: currencyID
              }
              donationDtoList.push(obj)
            }
          })
        } else {
          donationDtoList.push(item)
        }
      })
    } else if (details.amount) {
      const obj = {
        amount: details.amount,
        currencyId: currencyID
      }
      donationDtoList.push(obj)
    }
    const cardCharge = await getCardCharges(donationDtoList, details.sponsored)
    if (cardCharge && cardCharge.data) {
      return cardCharge.data
    }
  },
  async getSadaqahCardCharges ({ getters }, details) {
    console.log('getSadaqahCardCharges, sponsored', details)
    console.log('getSadaqahCardCharges getters.user', getters.user)
    const donationDtoList = []
    const email = (getters.user ? getters.user.email : '')
    let currencyID = 1
    if (details.currency !== 'R') {
      currencyID = 3
    }
    const obj = {
      email,
      donorID: (getters.user ? getters.user.donorID : ''),
      amount: details.total,
      currencyId: currencyID
    }

    donationDtoList.push(obj)
    const cardCharge = await getCardCharges(donationDtoList, details.sponsored)
    if (cardCharge && cardCharge.data) {
      return cardCharge.data
    }
  }
}

export default {
  actions
}
