import { Filesystem, Directory } from '@capacitor/filesystem'
import { FileOpener } from '@capacitor-community/file-opener'

const state = {
  downloadUri: null
}

const mutations = {
  setDownloadUri (state, uri) {
    state.downloadUri = uri
  }
}
const actions = {
  async downloadFile ({ commit, dispatch }, data) {
    console.log('data', data.data)
    try {
      // console.log('fileBlob', fileBlob)
      const reader = new FileReader()
      reader.readAsDataURL(data.data)

      reader.onloadend = async () => {
        // console.log('READER', reader)
        // console.log('READERresult', reader.result)
        const theData = reader.result
        console.log('theData', theData)
        const fileUri = await Filesystem.writeFile({
          path: data.fileName,
          data: theData,
          directory: Directory.Documents,
          recursive: true
        })
        await commit('setDownloadUri', fileUri.uri)
        await dispatch('openDownloadedFile')
        return true
      }
    } catch (err) {
      console.log('ERR', err)
      return err
    }
  },
  async downloadZip ({ commit, dispatch }, data) {
    console.log('data', data.data)
    try {
      // console.log('fileBlob', fileBlob)
      const reader = new FileReader()
      reader.readAsDataURL(data.data)

      reader.onloadend = async () => {
        // console.log('READER', reader)
        // console.log('READERresult', reader.result)
        const theData = reader.result
        console.log('theData', theData)
        const fileUri = await Filesystem.writeFile({
          path: data.fileName,
          data: theData,
          directory: Directory.Documents,
          recursive: true
        })
        await commit('setDownloadUri', fileUri.uri)
        await dispatch('openDownloadedZipFile')
        return true
      }
    } catch (err) {
      console.log('ERR', err)
      return err
    }
  },
  async openDownloadedFile ({ state }) {
    if (state.downloadUri !== '') {
      try {
        await FileOpener.open({
          filePath: state.downloadUri,
          contentType: 'application/pdf'
        })
      } catch (e) {
        console.error('Error opening file:', e)
      }
    }
  },
  async openDownloadedZipFile ({ state }) {
    if (state.downloadUri !== '') {
      try {
        await FileOpener.open({
          filePath: state.downloadUri,
          contentType: 'application/zip'
        })
      } catch (e) {
        console.error('Error opening file:', e)
      }
    }
  }
}

export default {
  state,
  mutations,
  actions
}
