import axios from 'axios'
import store from '../../store'

const getCardCharges = (donationDtoList, sponsored) => {
  const body = {
    donationDtoList,
    sponsored
  }
  return axios.post(`${process.env.VUE_APP_URL_BASE}/adms/api/donations/calculate/charge/amount`, body, {
    headers: {
      Authorization: `Bearer ${store.state.user.jwt}`,
      baseSystemType: 'PUBLIC_WEBSITE'
    }
  })
}

export { getCardCharges }
